import { Box } from '@mui/material';
import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { colorTheme } from '../const';

export default function Tag(props) {
  const theme = createTheme({
    typography: {
      fontFamily: `'Noto Sans JP', 'Yu Gothic', 'Meiryo', 'Hiragino Sans', 'Arial', 'sans-serif'`,
    },
  });

  function tagBox(tagStyle, tagText) {
    return (
      <ThemeProvider theme={theme}>
        <div style={{ display: "block" }}>
          <Box sx={{
            fontFamily: "'Noto Sans JP', 'Yu Gothic', 'Meiryo', 'Hiragino Sans', 'Arial', 'sans-serif'",
            display: "flex",
            alignItems: "center",  // 縦方向中央配置
            justifyContent: "center",  // 横方向中央配置
            height: { xs: "50px", sm: "60px", md: "70px", lg: "80px", xl: "90px" },
            width: { xs: "150px", sm: "180px", md: "210px", lg: "240px", xl: "270px" },
            fontSize: { xs: "16px", sm: "17px", md: "20px", lg: "25px", xl: "30px" },
            backgroundColor: `${colorTheme.palette.turquoiseBlue.main}`,
            color: "white",
            fontWeight: "bold",
            whiteSpace: "pre-line",
            padding: tagStyle === 'right' ? '0px 40px 0px 16px' : '0px 16px 0px 40px',  // パディング調整
          }}>
            {tagText}
          </Box>
        </div>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={colorTheme}>
      {tagBox(props.tagStyle, props.tagText)}
    </ThemeProvider>
  );
}
