import React from 'react';
import '../components-css/Header.css';
import { Link } from 'react-router-dom';
import MenuSharpIcon from '@mui/icons-material/MenuSharp';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Botton from '@mui/material/Button';
import Logo1 from '../image/logo/techno_logo1.webp';
import Logo2 from '../image/logo/techno_logo2.webp';
import SiteLogo from './Site-logo';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useLocation } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import {HashLink} from 'react-router-hash-link';

export default function HeaderBar(props) {
  const isMobileScreen = useMediaQuery({ query: '(max-width: 800px)' });
  const MenuText = props.Menutext;
  const MenuLink = props.MenuLink;
  let headerClassName = "header";
  let headerRightBtn = <div className='headerRight__placeHolder'></div>;
  const location = useLocation();
  const [state, setState] = React.useState({
    top: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const list = (anchor) => (
    <div className='List'>
      <Divider />
      <List className='List__LObject'
        role="presentation">
        {MenuText.map((text, index) => (
          <ListItem className='List__Item'>
            <ListItemButton>
              <ListItemText className='List__ItemText'>
                <HashLink to={MenuLink[index]} onClick={toggleDrawer(anchor, false)} onKeyDown={toggleDrawer(anchor, true)}>{text}</HashLink>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );
  // eslint-disable-next-line
  if (props.isRecruitPage != undefined && props.isRecruitPage == "1") {
    // 採用ページ
    let headerLeftObj =
      <div className='headerLeft__object'>
        <SiteLogo imageSrc={isMobileScreen ? process.env.PUBLIC_URL + Logo1 : process.env.PUBLIC_URL + Logo2} width="50%" height="80%" alt="logo" className="siteLogoRecruit"></SiteLogo>
      </div >;
    headerRightBtn = <Link to="/recruit/entry"><div className='headerRight__Botton'><Botton><div className='headerRight__Botton'><p sx={{fontFamily: `'Noto Sans JP', 'Yu Gothic', 'Meiryo', 'Hiragino Sans', 'Arial', 'sans-serif'` }} className="responsiveText">募集職種・エントリー</p></div></Botton></div></Link>;

    return (
      <div id="top" className={headerClassName}>
        <div className='headerLeft'>
          {headerLeftObj}
        </div>
        <Box
          component="nav"
          sx={{ width: { sm: 240 }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          {/* スマホ */}
          <Box
            sx={{
              display: { xs: 'block', sm: 'none' },
            }}
          >
            <div className='headerRight'>
              <div className='headerRight__object'>
                {headerRightBtn}
              </div>
              <div className='headerRight__object'>
                <div className='headerRight__MenuBotton'>
                  <IconButton
                    onClick={toggleDrawer('top', true)}
                    sx={{
                      display: { xs: 'block', sm: 'none' },
                    }}
                  >
                    <div className='headerRight__MenuSharpIcon'>
                      <MenuSharpIcon />
                      <div sx={{fontFamily: `'Noto Sans JP', 'Yu Gothic', 'Meiryo', 'Hiragino Sans', 'Arial', 'sans-serif'`}} className='headerRight__MenuSharpIconFont'>
                        <p>MENU</p>
                      </div>
                    </div>
                  </IconButton>
                  <Drawer
                    anchor='top'
                    open={state['top']}
                    onClick={toggleDrawer('top', false)}
                    onClose={toggleDrawer('top', true)}
                    className="List__Object"
                    sx={{fontFamily: `'Noto Sans JP', 'Yu Gothic', 'Meiryo', 'Hiragino Sans', 'Arial', 'sans-serif'`}}
                  >
                    {list('top')}
                  </Drawer>
                </div>
              </div>
            </div>
          </Box>

          {/* PC */}
          <Box
            sx={{
              width: 800,
              paddingTop: 3,
              display: { xs: 'none', sm: 'block' },
              fontSize: '1vw',
              fontFamily: `'Noto Sans JP', 'Yu Gothic', 'Meiryo', 'Hiragino Sans', 'Arial', 'sans-serif'`
            }}
            className="header_Right_PC"
          >
            <Stack direction="row" spacing={2} className='headerNav'>
              {MenuText.map((text, index) => (
                <Link to={MenuLink[index]}>{text}</Link>
              ))}
            </Stack>
          </Box>
        </Box>
      </div>
    )
  } else {
    // 企業ページ
    let headerLeftObj = <div className='headerLeft__object'><SiteLogo imageSrc={process.env.PUBLIC_URL + Logo2} width="70%" height="100%" alt="logo"></SiteLogo></div>;
    const pageNameList = {
      "/service": "サービス",
      "/about": "会社情報",
      "/contact": "お問い合わせ",
      "/privacy": "個人情報保護方針",
      "/security": "個人情報の取り扱いについて",
    };
    let pageName = pageNameList[location.pathname];
    if (location.pathname.match(/news/)) {
      pageName = "ニュース";
    }
    if (isMobileScreen) {
      return (
        // スマホ
        <div id="top" style={{ display: "flex" }}>
          <div className='headerLeft'>
            {headerLeftObj}
          </div>
          <Box
            component="nav"
            sx={{ width: { sm: 240 }, flexShrink: { sm: 0 } }}
            aria-label="mailbox folders"
          >
            <Box
              sx={{
                display: { xs: 'block', sm: 'none' },
              }}
            >
              <div className='headerRight'>
                <div className='headerRight__object'>
                  {headerRightBtn}
                </div>
                <div className='headerRight__object'>
                  <div className='headerRight__MenuBotton'>
                    <IconButton
                      onClick={toggleDrawer('top', true)}
                      sx={{
                        display: { xs: 'block', sm: 'none' },
                      }}
                    >
                      <div className='headerRight__MenuSharpIcon'>
                        <MenuSharpIcon />
                        <div className='headerRight__MenuSharpIconFont'>
                          <p>MENU</p>
                        </div>
                      </div>
                    </IconButton>
                    <Drawer
                      anchor='top'
                      open={state['top']}
                      onClick={toggleDrawer('top', false)}
                      onClose={toggleDrawer('top', true)}
                      className="List__Object"
                    >
                      {list('top')}
                    </Drawer>
                  </div>
                </div>
              </div>
            </Box>
          </Box>
        </div>
      )
    } else {
      // PC
      if (location.pathname === "/") {
        // 企業TOP
        return null;
      } else {
        return (
          <div id="top">
            <header id="header" >
              <div className="header-inner">
                <div className="sub_nav">
                  <Link to="">HOME</Link>
                  /
                  <Link to="privacy">PRIVACY POLICY</Link>
                  /
                  <HashLink to="/about#sec4">ACCESS</HashLink>
                  /
                  <a href="https://note.com/yoshida_colla" target="_blank" rel="noreferrer">BLOG</a>
                </div>
                <div className="sologan">
                  <h1>コラボテクノ株式会社「発想力で、新たな価値を創造する」</h1>
                </div>
              </div>
            </header>
            <nav id="nav" className="main-navigation clearfix" role="navigation">
              <div className="menu-topnav-container">
                <div className="sankaku"></div>
                <ul className="nav-menu">
                  <li className={location.pathname === "/service" ? "nav-service current" : "nav-service"}><Link to="service">サービス</Link></li>
                  <li className={location.pathname === "/about" ? "nav-about current" : "nav-about"}><Link to="about">会社情報</Link></li>
                  <li className={location.pathname === "/recruit" ? "nav-recruit current" : "nav-recruit"}><Link to="recruit">採用情報</Link></li>
                  <li className={location.pathname === "/contact" ? "nav-contact current" : "nav-contact"}><Link to="contact">お問い合わせ</Link></li>
                </ul>
              </div>
            </nav>
            <div className="mainbox2 clearfix">
              <div class="breadcrumbs" itemscope="" itemtype="https://schema.org/BreadcrumbList">
                <span itemprop="itemListElement" itemscope="" itemtype="http://schema.org/ListItem">
                  <Link to="" itemprop="item" className="home"><span itemprop="name">HOME</span></Link>
                  <meta itemprop="position" content="1" />
                </span>　　<span itemprop="itemListElement" itemscope="" itemtype="http://schema.org/ListItem"><span itemprop="name">{pageName}</span><meta itemprop="position" content="2" /></span>
              </div>
              <div class="logo">
                <p><SiteLogo imageSrc={process.env.PUBLIC_URL + Logo2} alt="logo" className="siteLogoRecruit"></SiteLogo></p>
              </div>
            </div>
          </div>
        )
      }
    }
  }
}