import * as React from 'react';
import Slider from "react-slick";
import newEmployeeImg1 from "../image/interviews/new_employee1_top.webp";
import newEmployeeImg4 from "../image/interviews/new_employee4_top.webp";
import newEmployeeImg5 from "../image/interviews/new_employee5_top.webp";
import newEmployeeImg6 from "../image/interviews/new_employee6_top.webp";
import newEmployeeImg2 from "../image/new-employee-02.webp";
import newEmployeeImg3 from "../image/new-employee-03.webp";
import jobChangeImg1 from "../image/job-change-01.webp";
import jobChangeImg2 from "../image/interviews/job_change2_top.webp";
import lifeWorkValanceImg1 from "../image/lifework-valance-01.webp";
import lifeWorkValanceImg2 from "../image/lifework-valance-02.webp";
import lifeWorkValanceImg3 from "../image/interviews/work_life_balance3_top.webp";
import leaderImg1 from "../image/interviews/leader1_top.webp";
import '../components-css/Slider.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { colorTheme } from '../const';

// 引数でカテゴリを指定（props.category）
// - memberAll: 全メンバー
// - newEmployee: 新卒採用
// - jobChange: 異業種転職
// - lifeWorkValance: ライフワークバランス
export default function ImageSlider(props) {

  const newEmployeeArray = [
    { profile: "N.K システムエンジニア 2023年入社", title: "自分の想像したことを\n自分で実現できるのが嬉しい", img: newEmployeeImg1, link: "/recruit/member/interview1" },
    { profile: "M.S システムエンジニア 2022年入社", title: "新卒から濃密な3年で自分の\nスキルと考え方の成長を実感", img: newEmployeeImg3, link: "/recruit/member/interview2" },
    { profile: "K.T システムエンジニア 2022年入社", title: "スキル次第で大きな仕事に\n挑めるのがエンジニアの魅力", img: newEmployeeImg2, link: "/recruit/member/interview3" },
    { profile: "K.K システムエンジニア 2024年入社", title: "エンジニアは努力がきちんと\n報われる職業", img: newEmployeeImg4, link: "/recruit/member/interview4" },
    { profile: "S.S システムエンジニア 2024年入社", title: "決め手はフレキシブルな\n働き方ができること", img: newEmployeeImg5, link: "/recruit/member/interview5" },
    { profile: "R.H システムエンジニア 2024年入社", title: "働き方の自由を求めたら\nエンジニアでした", img: newEmployeeImg6, link: "/recruit/member/interview6" }
  ];

  const jobChangeArray = [
    { profile: "Y.S システムエンジニア 2019年入社", title: "知人の後押しで書店員から\nプログラミングの世界へ", img: jobChangeImg1, link: "/recruit/member/job-change-interview1" },
    { profile: "Y.M システムエンジニア 2023年入社", title: "転職でプライベートのない\n激務から解放", img: jobChangeImg2, link: "/recruit/member/job-change-interview2" },
  ];

  const lifeWorkValanceArray = [
    { profile: "K.O システムエンジニア 2021年入社", title: '結婚したことで\n自分の仕事力がアップ', img: lifeWorkValanceImg1, link: "/recruit/member/work-life-interview1" },
    { profile: "T.Y システムエンジニア 2016年入社", title: "まるまる１ヶ月間\n\"育業\"の現場にシフト", img: lifeWorkValanceImg2, link: "/recruit/member/work-life-interview2" },
    { profile: "F.K システムエンジニア 2022年入社", title: "子育てと両立しながら\n楽しくフルタイム勤務", img: lifeWorkValanceImg3, link: "/recruit/member/work-life-interview3" },
  ];

  const leaderArray = [
    { profile: "T.Y システムエンジニア 2022年入社", title: 'めざしていたリーダーに\nキャリア3年で到達', img: leaderImg1, link: "/recruit/member/leader-interview1" }
  ];

  const memberAllArray = [...newEmployeeArray, ...jobChangeArray, ...lifeWorkValanceArray, ...leaderArray];

  const settings = {
    slidesToShow: props.slidesToShow,
    dots: false,
    autoplay: true,
    autoplaySpeed: 3000,
    infinite: true,
    responsive: [
      {
        //スマホ表示(幅480px以下の画面)
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          arrows: false,
          dots: true,
          swipe: true,
          infinite: true,
          autoplay: true,
          centerMode: true,
          centerPadding: "15%"
        }
      },
    ]
  };

  const theme = createTheme({
    typography: {
      fontFamily: `'Noto Sans JP', 'Yu Gothic', 'Meiryo', 'Hiragino Sans', 'Arial', 'sans-serif'`,
    },
  });

  if (props.category === 'memberAll') {
    arrayShuffle(memberAllArray);
    return (
      <ThemeProvider theme={theme}>
        <Slider {...settings} className="sliderWrapper">
          {memberAllArray.slice(0, 3).map((memberAll, index) => {
            return (
              <div key={index}>
                <div className='slideStyle'>
                  <a href={memberAll.link}><img className='imgStyle' src={process.env.PUBLIC_URL + memberAll.img} alt="" /></a>
                  <p style={{ color: colorTheme.palette.darkGray.main, fontFamily: `'Noto Sans JP', 'Yu Gothic', 'Meiryo', 'Hiragino Sans', 'Arial', 'sans-serif'`}} 
                    className='profileStyle'>{memberAll.profile}
                  </p>
                  <p style={{ color: colorTheme.palette.darkGray.main, fontFamily: `'Noto Sans JP', 'Yu Gothic', 'Meiryo', 'Hiragino Sans', 'Arial', 'sans-serif'` }} className='titleStyle'>
                    {memberAll.title.split('\n').map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                  </p>
                </div>
              </div>
            )
          })}
        </Slider>
      </ThemeProvider>
    );
  } else if (props.category === 'newEmployee') {
    return (
      <ThemeProvider theme={theme}>
        <Slider {...settings} className="sliderWrapper">
          {newEmployeeArray.map((newEmployee, index) => {
            return (
              <div key={index}>
                <div className='slideStyle'>
                  <a href={newEmployee.link}><img className='imgStyle' src={process.env.PUBLIC_URL + newEmployee.img} alt="" /></a>
                  <p style={{ color: colorTheme.palette.darkGray.main, fontFamily: `'Noto Sans JP', 'Yu Gothic', 'Meiryo', 'Hiragino Sans', 'Arial', 'sans-serif'` }} className='profileStyle'>
                    {newEmployee.profile}
                  </p>
                  <p style={{ color: colorTheme.palette.darkGray.main, fontFamily: `'Noto Sans JP', 'Yu Gothic', 'Meiryo', 'Hiragino Sans', 'Arial', 'sans-serif'` }} className='titleStyle'>
                    {newEmployee.title.split('\n').map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                  </p>
                </div>
              </div>
            )
          })}
        </Slider>
      </ThemeProvider>
    );
  } else if (props.category === 'jobChange') {
    return (
      <ThemeProvider theme={theme}>
        <Slider {...settings} className="sliderWrapper">
          {jobChangeArray.map((jobChange, index) => {
            return (
              <div key={index}>
                <div className='slideStyle'>
                  <a href={jobChange.link}><img className='imgStyle' src={process.env.PUBLIC_URL + jobChange.img} alt="" /></a>
                  <p style={{ color: colorTheme.palette.darkGray.main, fontFamily: `'Noto Sans JP', 'Yu Gothic', 'Meiryo', 'Hiragino Sans', 'Arial', 'sans-serif'`  }} className='profileStyle'>
                    {jobChange.profile}
                  </p>
                  <p style={{ color: colorTheme.palette.darkGray.main, fontFamily: `'Noto Sans JP', 'Yu Gothic', 'Meiryo', 'Hiragino Sans', 'Arial', 'sans-serif'`  }} className='titleStyle'>
                    {jobChange.title.split('\n').map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                  </p>
                </div>
              </div>
            )
          })}
        </Slider>
      </ThemeProvider>
    );
  } else if (props.category === 'lifeWorkValance') {
    return (
      <ThemeProvider theme={theme}>
        <Slider {...settings} className="sliderWrapper">
          {lifeWorkValanceArray.map((lifeWorkValance, index) => {
            return (
              <div key={index}>
                <div className='slideStyle'>
                  <a href={lifeWorkValance.link}><img className='imgStyle' src={process.env.PUBLIC_URL + lifeWorkValance.img} alt="" /></a>
                  <p style={{ color: colorTheme.palette.darkGray.main, fontFamily: `'Noto Sans JP', 'Yu Gothic', 'Meiryo', 'Hiragino Sans', 'Arial', 'sans-serif'`  }} className='profileStyle'>
                    {lifeWorkValance.profile}
                  </p>
                  <p style={{ color: colorTheme.palette.darkGray.main, fontFamily: `'Noto Sans JP', 'Yu Gothic', 'Meiryo', 'Hiragino Sans', 'Arial', 'sans-serif'`  }} className='titleStyle'>
                    {lifeWorkValance.title.split('\n').map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                  </p>
                </div>
              </div>
            )
          })}
        </Slider>
      </ThemeProvider>
    );
  } else if (props.category === 'leader') {
    return (
      <ThemeProvider theme={theme}>
        <Slider {...settings} className="sliderWrapper">
          {leaderArray.map((leader, index) => {
            return (
              <div key={index}>
                <div className='slideStyle'>
                  <a href={leader.link}><img className='imgStyle' src={process.env.PUBLIC_URL + leader.img} alt="" /></a>
                  <p style={{ color: colorTheme.palette.darkGray.main, fontFamily: `'Noto Sans JP', 'Yu Gothic', 'Meiryo', 'Hiragino Sans', 'Arial', 'sans-serif'`  }} className='profileStyle'>
                    {leader.profile}
                  </p>
                  <p style={{ color: colorTheme.palette.darkGray.main, fontFamily: `'Noto Sans JP', 'Yu Gothic', 'Meiryo', 'Hiragino Sans', 'Arial', 'sans-serif'`  }} className='titleStyle'>
                    {leader.title.split('\n').map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                  </p>
                </div>
              </div>
            )
          })}
        </Slider>
      </ThemeProvider>
    );
  }

  // 配列の中身をシャッフルする
  function arrayShuffle(array) {
    for (let i = (array.length - 1); 0 < i; i--) {

      let r = Math.floor(Math.random() * (i + 1));
      let tmp = array[i];

      array[i] = array[r];
      array[r] = tmp;
    }
    return array;
  }
}
