import img2 from '../image/IMG_9786.webp';
import img3 from '../image/IMG_0125.webp';
import img5 from '../image/IMG_9720.webp';
import img7 from '../image/IMG_0369.webp';
import img10 from '../image/IMG_0706.webp';
import img11 from '../image/IMG_0819.webp';
import img12 from '../image/IMG_9973.webp';
import img13 from '../image/IMG_0935.webp';
import img14 from '../image/IMG_2058.webp';
import img15 from '../image/IMG_9318.webp';
import img16 from '../image/IMG_9365.webp';
import img17 from '../image/IMG_9445.webp';
import img18 from '../image/IMG_9808.webp';
import img19 from '../image/IMG_9631.webp';

const recruitTopSliderImages = [
    img2, img3, img5, img7, img10,
    img11, img12, img13, img14, img15, img16, img17, img18, img19
];
export default recruitTopSliderImages;
